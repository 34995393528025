import { TableProps } from './interfaces';
import * as S from './styles';

export function Table({ columns, data, isLoading }: TableProps) {
  return (
    <S.Container>
      <S.ContainerTable>
        <S.THead>
          <S.Tr>
            {columns?.length &&
              columns.map((column, index) => (
                <S.Th
                  className={column.className}
                  key={column.key + index}
                  data-testid={`column-sort-${column.key}-${index}`}
                >
                  <span>{column.title}</span>
                </S.Th>
              ))}
          </S.Tr>
        </S.THead>
        {isLoading ? (
          <S.NoData>
            <span>Loading...</span>
          </S.NoData>
        ) : data?.length && data.length > 0 ? (
          <S.TBody>
            {data.map(({ id, data }) => (
              <S.Tr key={id}>
                {Object.entries(data).map(([key], index) => (
                  <S.Td
                    key={index}
                    style={data[key]?.style}
                    className={data[key]?.className}
                  >
                    {data[key]?.value}
                  </S.Td>
                ))}
              </S.Tr>
            ))}
          </S.TBody>
        ) : (
          <S.NoData>
            <span>No data</span>
          </S.NoData>
        )}
      </S.ContainerTable>
    </S.Container>
  );
}
