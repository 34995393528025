import axios, { AxiosError } from 'axios';
import { env } from 'configs';

const api = axios.create({
  baseURL: env.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// api.interceptors.response.use(
//   response => {
//     return response;
//   },
//   (error: AxiosError | any) => {
//     if (error.response?.status === 401) {
//       localStorage.clear();
//       window.location.href = '/';
//     }
//   },
// );

export default api;
