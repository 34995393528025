import { useCallback, useEffect, useState } from 'react';
import { IoMdArrowBack } from 'react-icons/io';

import { message } from 'antd';
import { formatCurrency } from 'common/utils/masks';
import { Table } from 'components';
import { Data } from 'components/Table/interfaces';
import { Layout } from 'containers';
import { SubscriptionModel } from 'models/Subscription';
import api from 'services/api';

export function Dashboard() {
  const [tableData, setTableData] = useState<Data[]>([]);
  const [selectedUser, setSelectedUser] = useState<SubscriptionModel | null>(
    null,
  );

  const handleGetSubscriptions = useCallback(async () => {
    try {
      const { data } = await api.get<SubscriptionModel[]>(
        '/admin/shop-manager/list',
      );

      handleTransformDataInTableData(data);
    } catch (err) {
      message.error('Ocorreu um erro ao buscar as assinaturas!');
    }
  }, []);

  const handleSelectUser = useCallback((user: SubscriptionModel) => {
    setSelectedUser(user);
  }, []);

  const handleTransformDataInTableData = useCallback(
    (subscriptions: SubscriptionModel[]) => {
      const formattedTableData = subscriptions.map(data => ({
        id: data.id,
        data: {
          name: {
            value: data.name,
          },
          document: {
            value: data.document
              ? data.document.replace(
                  /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                  '$1.$2.$3/$4-$5',
                )
              : 'Não informado',
          },
          email: {
            value: data.email,
          },
          phone: {
            value: data.phone,
          },
          actions: {
            value: (
              <button onClick={() => handleSelectUser(data)}>
                Visualizar dados
              </button>
            ),
          },
        },
      }));

      setTableData(formattedTableData);
    },
    [],
  );

  useEffect(() => {
    handleGetSubscriptions();
  }, []);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      className: 'test',
    },
    {
      title: 'Documento',
      dataIndex: 'document',
      key: 'document',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Celular',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Ações',
      dataIndex: 'actions',
      key: 'actions',
    },
  ];

  return (
    <Layout>
      <div>
        <section>
          <div className="flex justify-between align-middle">
            <h1 className="text-2xl">Clientes ativos</h1>
          </div>
          <div className="px-0 mt-10">
            <div className="max-w-2xl my-10 lg:max-w-7xl">
              {selectedUser && (
                <>
                  <button
                    onClick={() => setSelectedUser(null)}
                    className="mb-10 flex justify-between"
                  >
                    <IoMdArrowBack />
                    <span style={{ marginTop: -4, marginLeft: 10 }}>
                      Voltar
                    </span>
                  </button>
                  <div className="bg-white">
                    <div className="">
                      <div>
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                          {selectedUser.name}
                        </h2>
                        <p className="mt-4 text-gray-500">
                          Informações sobre o assinante:
                        </p>

                        <dl className="mt-16 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8">
                          <div>
                            <dt
                              className="font-medium text-gray-900"
                              style={{ fontSize: '1.25rem' }}
                            >
                              Informações pessoais
                            </dt>
                            <dd className="mt-2 text-sm text-gray-500">
                              <div className="mt-2 text-gray-900">
                                <b>Id: </b>
                                <span>{selectedUser.id}</span>
                              </div>
                              <div className="mt-2 text-gray-900">
                                <b>Nome: </b>
                                <span>{selectedUser.name}</span>
                              </div>
                              <div className="mt-2 text-gray-900">
                                <b>Email: </b>
                                <span>{selectedUser.email}</span>
                              </div>
                              <div className="mt-2 text-gray-900">
                                <b>Documento: </b>
                                <span>
                                  {selectedUser.document.replace(
                                    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                                    '$1.$2.$3/$4-$5',
                                  )}
                                </span>
                              </div>
                              <div className="mt-2 text-gray-900">
                                <b>Celular: </b>
                                <span>{selectedUser.phone}</span>
                              </div>
                            </dd>
                          </div>

                          <div />

                          <div className="pt-4">
                            <dt
                              className="font-medium text-gray-900"
                              style={{ fontSize: '1.25rem' }}
                            >
                              Empresa
                            </dt>
                            <dd className="mt-2 text-sm text-gray-500">
                              <div className="mt-2 text-gray-900">
                                <b>Razão social: </b>
                                <span>{selectedUser.company}</span>
                              </div>
                              <div className="mt-2 text-gray-900">
                                <b>Inscrição: </b>
                                <span>{selectedUser.registration}</span>
                              </div>
                            </dd>
                          </div>
                        </dl>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {!selectedUser && <Table columns={columns} data={tableData} />}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
