import React, { createContext, useCallback, useState, useContext } from 'react';

import { message } from 'antd';
import api from 'services/api';

interface User {
  hasLoggedIn: boolean;
}

interface UserState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface UserContextData {
  loading: boolean;
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
}

const UserContext = createContext<UserContextData>({} as UserContextData);

const UserProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<UserState>(() => {
    const token = localStorage.getItem('@folhetonamao:token');
    const user = localStorage.getItem('@folhetonamao:user');

    if (token && user) {
      api.defaults.headers.common['authorization'] = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as UserState;
  });
  const [loading, setLoading] = useState(false);

  const handleSignOut = useCallback(async () => {
    try {
      await api.get('user/auth');

      localStorage.removeItem('@folhetonamao:token');
      localStorage.removeItem('@folhetonamao:user');

      setData({} as UserState);
      window.location.href = '/';
    } catch (err) {
      localStorage.removeItem('@folhetonamao:token');
      localStorage.removeItem('@folhetonamao:user');

      setData({} as UserState);
    }
  }, []);

  const handleSignIn = useCallback(async ({ email, password }) => {
    setLoading(true);
    const authResponse = await api.post('user/auth', {
      email,
      password,
    });

    try {
      const { token } = authResponse.data;

      localStorage.setItem('@folhetonamao:token', token);

      api.defaults.headers.common['authorization'] = `Bearer ${token}`;

      const user = { hasLoggedIn: true };

      localStorage.setItem('@folhetonamao:user', JSON.stringify(user));

      setData({ token, user });
    } catch (err) {
      setLoading(false);
      message.error('Não foi possível realizar o login');
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        loading,
        user: data.user,
        signIn: handleSignIn,
        signOut: handleSignOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useUser(): UserContextData {
  const context = useContext(UserContext);

  return context;
}

export { UserProvider, useUser };
