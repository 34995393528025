import { font } from 'common/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  font-family: ${font.family.Inter};
  position: relative;
`;

export const ContainerTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const THead = styled.thead`
  width: 100%;
`;

export const TBody = styled.tbody`
  width: 100%;
`;

export const Tr = styled.tr`
  transition: background 0.3s ease;
  :hover {
    background-color: #fafafa;
  }
`;

export const Td = styled.td`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #282828;
  border-bottom: 1px solid #f0f0f0;
  text-align: left;
  padding: 19px;
`;

export const Th = styled.th`
  background-color: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  text-align: left;
  padding: 19px;
  span {
    display: block;
    font-weight: 500;
    font-size: 16px;
    color: #000000d9;
  }
  div {
    display: flex;
    align-items: center;
  }
`;

export const NoData = styled.div`
  position: absolute;
  width: 100%;
  height: 120px;
  padding: 30px 0px;
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
`;
